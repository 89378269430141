@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
body,
html {
  font-family: "Poppins", sans-serif !important;
}
input[type="date"] {
  @apply text-sm text-info;
}

nput[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
}

input:focus,
button:focus,
textarea:focus {
  outline: none !important;
}

* {
  outline: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: white !important;
  filter: invert(1);
}
.md-shadow {
  box-shadow: 0px -4px 23px -11px rgba(0, 0, 0, 1);
}
input {
  color-scheme: light;
}
